<template>
  <div class="panel-contanier">
    <div v-if="showLine" class="line"></div>
    <div class="top-cotent" :style="topContentSty">
      <div class="top flex_c_c" :style="topSty">
        <svg-icon v-if="showIcon" iconClass="panel-icon" className="icon" />
        <slot name="header">
          <span>{{ title }}</span>
        </slot>
      </div>
      <slot name="opt">
      </slot>
    </div>
    <div class="panel-content" :style="panelContentSty">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: 'auto'
        },
        showIcon: {
          type: Boolean,
          default: true
        },
        showLine: {
          type: Boolean,
          default: false
        },
        topSty: {
          type: Object
        },
        panelContentSty: {
          type: Object
        },
        topContentSty: {
          type: Object
        }
    }
}
</script>

<style lang="less" scoped>
.panel-contanier{
    position: relative;
    width: calc(100% - 64px);
    margin: 0 auto;
    height: auto;
    .line {
      width: 100%;
      height: 1px;
      background: #F7F7F7;
      margin-bottom: 20px;
    }
    .top-cotent {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    .top {
      width: auto;
      height: 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.16);
      border-radius: 4px 4px 4px 4px;
      padding: 0 14px;
      box-sizing: border-box;
      display: flex;
      z-index: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395F3;
        .icon {
            font-size: 24px;
            margin: 8px 0 8px 8px;
        }
        span {
            width: auto;
            height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: #25396F;
            line-height: 40px;
            margin-left: 8px;
        }
    }
    .opt {
      z-index: 1;
    }
    .panel-content {
        width: 100%;
        height: auto;
    }
}
</style>
