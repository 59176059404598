var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "panel-contanier" }, [
    _vm.showLine ? _c("div", { staticClass: "line" }) : _vm._e(),
    _c(
      "div",
      { staticClass: "top-cotent", style: _vm.topContentSty },
      [
        _c(
          "div",
          { staticClass: "top flex_c_c", style: _vm.topSty },
          [
            _vm.showIcon
              ? _c("svg-icon", {
                  attrs: { iconClass: "panel-icon", className: "icon" },
                })
              : _vm._e(),
            _vm._t("header", function () {
              return [_c("span", [_vm._v(_vm._s(_vm.title))])]
            }),
          ],
          2
        ),
        _vm._t("opt"),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "panel-content", style: _vm.panelContentSty },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }